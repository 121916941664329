<template>
    <r-e-dialog :title="title" :visible.sync="dialogVisible" :show-footer="type!==0" width="800px"
                @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <div class="flex justify-between">
                <el-form-item label="活动名称" prop="promotionUuid">
                    <el-select v-model="formPublish.promotionUuid" placeholder="请选择" :disabled="type===0"
                               style="width: 230px;">
                        <el-option v-for="item in promotionList" :key="item.uuid" :label="item.activityName"
                                   :value="item.uuid"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="优惠券名称" prop="couponName">
                    <el-input v-model="formPublish.couponName" placeholder="请填写优惠券数量" :disabled="type===0"
                              style="width: 230px;"/>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="面值" prop="amountType">
                    <el-radio-group v-model="formPublish.amountType" @input="amountTypeInput" :disabled="type===0">
                        <el-radio :label="1">
                            <el-select v-model="formPublish.amountTypeCode" placeholder="请选择"
                                       :disabled="formPublish.amountType!==1||type===0" style="width: 230px;">
                                <el-option v-for="item in amountTypeList" :key="item.code" :label="item.codeName"
                                           :value="item.code"/>
                            </el-select>
                        </el-radio>
                        <!-- 2023.08.04 张晓瑜修改优惠券页面 -->
                        <!-- <el-radio :label="2">
                            <el-input v-model="formPublish.amount" type="number" placeholder="请输入内容"
                                      :disabled="formPublish.amountType!==2||type===0" style="width: 230px;"/>
                        </el-radio> -->
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="订单金额" prop="useMinAmountType">
                    <el-radio-group v-model="formPublish.useMinAmountType" @input="useMinAmountTypeInput"
                                    :disabled="type===0">
                        <el-radio :label="0">无限制</el-radio>
                        <el-radio :label="1">
                            满
                            <el-input v-model="formPublish.useMinAmount" type="number" placeholder="请输入数字"
                                      :disabled="formPublish.useMinAmountType!==1||type===0"
                                      style="width: 115px;margin: 0 10px"/>
                            可用
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="发放范围">
                    <el-select v-model="formPublish.communityList" placeholder="请选择" style="width: 170px;"
                               :disabled="whole || type===0" multiple>
                        <el-option v-for="item in communityList" :key="item.uuid" :label="item.name"
                                   :value="item.uuid"/>
                    </el-select>
                    <el-tag :type="whole?'':'info'" style="cursor: pointer;margin-left: 14px" @click="quanxuan">全选
                    </el-tag>
                </el-form-item>
                <el-form-item label="发放数量" prop="releasedQuantity">
                    <el-input v-model="formPublish.releasedQuantity" type="number" placeholder="请填写发放数量"
                              style="width: 230px;" :disabled="type===0"/>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="有效期" prop="endDate">
                    <el-date-picker v-model="expirationDate" type="daterange" :disabled="type===0" format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                                    end-placeholder="结束日期" style="width: 230px;" @change="expirationDateChange"/>
                </el-form-item>
                <el-form-item label="发放规则" prop="grantRule">
                    <el-select v-model="formPublish.grantRule" placeholder="请选择" style="width: 230px;"
                               :disabled="type===0">
                        <el-option v-for="item in grantRuleList" :key="item.code" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="使用范围" prop="useAmountRule">
                    <el-select v-model="formPublish.useAmountRule" placeholder="请选择" style="width: 230px;"
                               :disabled="type===0">
                        <el-option v-for="item in useAmountRuleList" :key="item.code" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
                <!-- 2023/08/21 张晓瑜新增已发数量 -->
                <el-form-item label="已发数量" prop="alreadyReleasedQuantity" v-if="type === 0" >
                    <el-input v-model="formPublish.alreadyReleasedQuantity" type="number"
                              style="width: 230px;" :disabled="type===0"/>
                </el-form-item>
            </div>
            <el-form-item label="备注" prop="common">
                <el-input v-model="formPublish.common" type="textarea" :disabled="type===0"
                          :autosize="{ minRows: 3, maxRows: 5}" placeholder="请填写活动介绍"/>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {MessageSuccess, MessageWarning} from "@custom/message";
import {saveCoupon, getCouponInfo, updateCoupon, getPromotionList} from "@/api/activity-management";
import {getCommunityListApi} from "@/api/community-management"
import {ParseIntFormat, ParseFloatFormat, timeFormat} from "@custom";

export default {
    name: "layer-save-coupon",
    data() {
        return {
            title: '',
            type: 0,
            dialogVisible: false,
            formPublish: {
                promotionUuid: null,
                couponName: null,
                amountType: null,               //面值类型 1按照租金下拉框   2按照固定金额
                amountTypeCode: null,           //amountType==1时传入
                amount: null,                   //amountType==2时传入
                releasedQuantity: null,         //发放数量
                startDate: null,                //开始时间
                endDate: null,                  //结束时间
                common: null,
                useMinAmountType: null,         //订单金额 -- 使用时金额限定  0无限定  1金额限定  ...
                useMinAmount: null,             //useMinAmountType==1时传入 满 ？？？ 可用
                useAmountRule: null,            //使用范围  使用账单字典
                scopeType: null,                //发放类型  全部时传0   手动选择小区时传1
                communityList: [],              //scopeType == 1时传入  小区uuid列表
                grantRule: null,                //发放规则 字典601130下
                status: 0,                       //状态  0正常 1作废
                usedQuantity: null,              //已发数量
                alreadyReleasedQuantity:null     // 已发放数量
            },
            promotionList: [],
            amountTypeList: [],
            useAmountRuleList: [],
            grantRuleList: [],
            communityList: [],
            expirationDate: [],
            whole: false,
            rules: {
                promotionUuid: [{required: true, message: '请选择活动', trigger: 'change'}],
                couponName: [{required: true, message: '请输入优惠券名称', trigger: 'blur'}],
                amountType: [{required: true, message: '请选择面值类型', trigger: 'change'}],
                useMinAmountType: [{required: true, message: '请选择订单金额', trigger: 'change'}],
                releasedQuantity: [{required: true, message: '请输入发放数量', trigger: 'blur'}],
                endDate: [{required: true, message: '请选择有效期', trigger: 'change'}],
                grantRule: [{required: true, message: '请选择发放规则', trigger: 'change'}],
                useAmountRule: [{required: true, message: '请选择使用范围', trigger: 'change'}],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    async created() {
        const {list} = await getPromotionList({activityType: -1, status: 0});
        this.promotionList = list;
        this.amountTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "601120");
        this.grantRuleList = await this.$store.dispatch("app/getDictionaryByCodeActions", "601130");
        this.useAmountRuleList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500500");
        let {list: communityList} = await getCommunityListApi();
        communityList = communityList.filter(({apartmentsCount}) => apartmentsCount > 0);
        communityList = communityList.map(({name, uuid}) => {
            return {name, uuid}
        });
        this.communityList = communityList;
    },
    methods: {
        async openDialog(data = null, type = 0) {
            const that = this;
            that.type = type;
            console.log('type',type);
            switch (type) {
                case 0:
                    that.title = '优惠券详情';
                    break;
                case 1:
                    that.title = '添加优惠券';
                    break;
                case 2:
                    that.title = '修改优惠券';
                    break;
            }
            if (data) {
                const {uuid} = data;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading(loadingOptions);
                getCouponInfo({uuid}).then(res => {
                    const {info} = res;
                    if (info) {
                        const {
                            amount, amountType, amountTypeCode, common, communityList, couponName, endDate, grantRule,
                            promotionUuid, releasedQuantity, scopeType, startDate, status, useAmountRule, useMinAmount,
                            useMinAmountType, uuid,alreadyReleasedQuantity
                        } = info;
                        that.formPublish.promotionUuid = promotionUuid;
                        that.formPublish.couponName = couponName;
                        that.formPublish.amountType = amountType;
                        that.formPublish.amountTypeCode = amountTypeCode === 0 ? null : amountTypeCode;
                        that.formPublish.amount = ParseFloatFormat(amount);
                        that.formPublish.releasedQuantity = releasedQuantity;
                        that.formPublish.startDate = timeFormat(new Date(startDate));
                        that.formPublish.endDate = timeFormat(new Date(endDate));
                        that.expirationDate = [that.formPublish.startDate, that.formPublish.endDate];
                        that.formPublish.common = common;
                        that.formPublish.useMinAmountType = useMinAmountType;
                        that.formPublish.useMinAmount = ParseFloatFormat(useMinAmount);
                        that.formPublish.useAmountRule = useAmountRule;
                        that.formPublish.scopeType = scopeType;
                        that.whole = scopeType === 0;
                        that.formPublish.communityList = communityList;
                        that.formPublish.grantRule = grantRule;
                        that.formPublish.status = status;
                        that.formPublish.uuid = uuid;
                        that.formPublish.alreadyReleasedQuantity = alreadyReleasedQuantity
                    }
                    that.dialogVisible = true;
                }).finally(() => loading.close());
            } else {
                that.dialogVisible = true;
            }
        },
        handleSubmitPublish() {
            let that = this;
            // 表单提交
            that.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const type = that.type;
                    let {
                        uuid, promotionUuid, couponName, amountType, amountTypeCode, amount, releasedQuantity,
                        startDate, endDate, common, useMinAmountType, useMinAmount, useAmountRule, scopeType,
                        communityList, grantRule, status,
                    } = that.formPublish;
                    amount = Number(amount || 0);
                    useMinAmount = Number(useMinAmount || 0);
                    releasedQuantity = Number(releasedQuantity || 0);
                    const whole = that.whole;
                    if (amountType === 1) {
                        amount = 0;
                        if (amountTypeCode === null) {
                            this.$alert('请选择下拉面值金额', '提醒', {confirmButtonText: '确定'});
                            return;
                        }
                    } else {
                        amountTypeCode = 0;
                        if (amount <= 0) {
                            this.$alert('请输入正确面值金额', '提醒', {confirmButtonText: '确定'});
                            return;
                        }
                        amount = ParseIntFormat(amount);
                    }
                    if (useMinAmountType === 0) {
                        useMinAmount = 0;
                    } else {
                        if (useMinAmount <= 0) {
                            this.$alert('请输入正确订单金额', '提醒', {confirmButtonText: '确定'});
                            return;
                        }
                        useMinAmount = ParseIntFormat(useMinAmount);
                    }
                    if (whole) {
                        scopeType = 0;
                        communityList = [];
                    } else {
                        scopeType = 1;
                        if (communityList.length === 0) {
                            this.$alert('请选择发放小区', '提醒', {confirmButtonText: '确定'});
                        }
                    }
                    const paramData = {
                        promotionUuid, couponName, amountType, amountTypeCode, amount, releasedQuantity,
                        startDate, endDate, common, useMinAmountType, useMinAmount, useAmountRule, scopeType,
                        communityList, grantRule, status,
                    };

                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading(loadingOptions);

                    if (type === 2) {
                        paramData.uuid = uuid;
                        updateCoupon(paramData).then(res => {
                            MessageSuccess("修改成功");
                            that.handleCancel();
                        }).finally(() => loading.close());
                    } else {
                        saveCoupon(paramData).then(res => {
                            MessageSuccess("添加成功");
                            that.handleCancel();
                        }).finally(() => loading.close());
                    }
                } else MessageWarning("请填写必填值", 1000);
            });
        },
        handleCancel() {
            this.dialogVisible = false;
            this.formPublish = {
                promotionUuid: null, couponName: null, amountType: null, amountTypeCode: null, amount: null,
                releasedQuantity: null, startDate: null, endDate: null, common: null, useMinAmountType: null,
                useMinAmount: null, useAmountRule: null, scopeType: null, communityList: [], grantRule: null,
                status: 0,
            }
            //2022/03/21 调用父组件方法 刷新未支付房租账单列表
            this.$emit('handleSearch');
        },
        expirationDateChange(value) {
            if (value && value.length > 0) {
                const [startDate, endDate] = value;
                this.formPublish.startDate = startDate;
                this.formPublish.endDate = endDate;
            } else {
                this.formPublish.startDate = null;
                this.formPublish.endDate = null;
            }
        },
        amountTypeInput(value) {
            if (value) {
                if (value === 1) {
                    this.formPublish.amount = null;
                } else {
                    this.formPublish.amountTypeCode = null;
                }
            }
        },
        useMinAmountTypeInput(value) {
            if (value || value === 0) {
                this.formPublish.useMinAmount = null;
            }
        },
        quanxuan() {
            if (this.type !== 0) {
                this.whole = !this.whole;
            }
        }
    },
    watch: {}
}
</script>

<style lang="scss" scoped>
/deep/ .el-date-editor .el-range-separator {
    padding: 0 5px;
    width: 15%;
    color: #303133;
}
</style>